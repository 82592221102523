'use strict';

(function() {
  function NotesResource($resource) {
    return $resource('api/v1/notes/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      filterNotes: {
        method: 'POST',
        params: {
          controller: 'filter-data'
        }
      },
    });
  }

  angular.module('windmanagerApp')
    .factory('Notes', NotesResource);

})();
